<!-- 商品管理页面 -->
<template>
  <div class="commodityManagement">
    <div class="top-area clr">
      <Button type="primary">新增</Button>
      <Input style="width:240px" class="fr" search placeholder="请输入..." />
    </div>
    <div class="table-area">
      <Table :columns="columns" :data="dataList" size="small" :height="tableHeight">
        <template slot-scope="{ row }" slot="recommend_image">
          <img style="width:36px;height:36px;margin-top:5px" :src="row.slider_image[0]" alt />
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <Button type="primary" size="small" style="margin-right: 5px" @click="edit(index)">修改</Button>
          <Button type="error" size="small" @click="remove(index)">删除</Button>
        </template>
      </Table>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page
        :total="total"
        :current="current"
        show-total
        @on-change="pageChage"
        @on-page-size-change="pageSizeChage"
      ></Page>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableHeight: window.innerHeight - 250,
      total: 0,
      current: 1,
      columns: [
        {
          title: '商品id',
          key: 'id'
        },
        {
          title: '商品图',
          key: 'recommend_image',
          slot: 'recommend_image'
        },
        {
          title: '商品名称',
          key: 'store_name',
          width: 240,
        },
        {
          title: '商品类型',
          key: 'product_type'
        },
        {
          title: '商品价格',
          key: 'price'
        },
        {
          title: '销量',
          key: 'sales'
        },
        {
          title: '库存',
          key: 'stock'
        },
        {
          title: '操作',
          width: 160,
          slot: 'action',
        },
      ],
      dataList: [
        {
          "id": 93,
          "mer_id": 0,
          "image": "//img.alicdn.com/imgextra/i2/2949956872/O1CN01cxrdCn20dQQYCrDcZ_!!0-item_pic.jpg",
          "recommend_image": "https://v5.crmeb.net/uploads/attach/2021/12/13/49c3a175597f0824a5cd0e93092e665e.png",
          "slider_image": [
            "//img.alicdn.com/imgextra/i2/2949956872/O1CN01cxrdCn20dQQYCrDcZ_!!0-item_pic.jpg",
            "//img.alicdn.com/imgextra/i2/2949956872/O1CN01UUi1OG20dQQPaH8Rv_!!0-item_pic.jpg",
            "//img.alicdn.com/imgextra/i4/2949956872/O1CN01Qq6IvA20dQQWfz21d_!!0-item_pic.jpg"
          ],
          "store_name": "【LESHUCANGHUA】创意插画动漫人物月野兔SailorMoon水兵月明信片",
          "store_info": "商品简介08:31",
          "keyword": "",
          "bar_code": "",
          "cate_id": "82",
          "price": "299.00",
          "vip_price": "0.00",
          "ot_price": "354.00",
          "postage": "0.00",
          "unit_name": "件",
          "sort": 890,
          "sales": 81,
          "stock": "1741",
          "is_show": 1,
          "is_hot": 1,
          "is_benefit": 0,
          "is_best": 0,
          "is_new": 0,
          "is_virtual": 0,
          "virtual_type": 0,
          "add_time": 1636789299,
          "is_postage": 0,
          "is_del": 0,
          "mer_use": 0,
          "give_integral": "100.00",
          "cost": "244.00",
          "is_seckill": 0,
          "is_bargain": null,
          "is_good": 0,
          "is_sub": 1,
          "is_vip": 0,
          "ficti": 0,
          "browse": 0,
          "code_path": "",
          "soure_link": "",
          "temp_id": 0,
          "spec_type": 1,
          "activity": "0,3,2,1",
          "spu": "5199569781285",
          "label_id": "",
          "video_link": "",
          "command_word": "",
          "recommend_list": "89,92",
          "vip_product": 0,
          "presale": 0,
          "presale_start_time": 0,
          "presale_end_time": 0,
          "presale_day": 0,
          "logistics": "1,2",
          "freight": 2,
          "custom_form": "[]",
          "is_limit": 0,
          "limit_type": 0,
          "limit_num": 0,
          "min_qty": 1,
          "collect": 56,
          "likes": 0,
          "visitor": 1848,
          "cate_name": "",
          "stock_attr": true,
          "product_type": "普通商品"
        },
        {
          "id": 109,
          "mer_id": 0,
          "image": "https://demo26.crmeb.net/uploads/attach/2021/12/20211210/ee1618b3334641c707cadbb9e14a5bae.jpg",
          "recommend_image": "https://demo26.crmeb.net/uploads/attach/2022/04/20220401/1601eee5c3b689341675b13f5e0500a2.jpg",
          "slider_image": [
            "https://demo26.crmeb.net/uploads/attach/2021/12/20211210/ee1618b3334641c707cadbb9e14a5bae.jpg"
          ],
          "store_name": "CROFT HOUSE意式极简四斗柜/现代简约高款抽屉柜卧室工业风FOMIX储物柜  【铝合金＆皮艺饰面】 BRONSON DRESSER",
          "store_info": "CROFT HOUSE意式极简四斗柜/现代简约高款抽屉柜卧室工业风FOMIX储物柜  【铝合金＆皮艺饰面】 BRONSON DRESSER",
          "keyword": "",
          "bar_code": "",
          "cate_id": "53",
          "price": "5980.00",
          "vip_price": "0.00",
          "ot_price": "6999.00",
          "postage": "0.00",
          "unit_name": "件",
          "sort": 555,
          "sales": 368,
          "stock": "416",
          "is_show": 1,
          "is_hot": 1,
          "is_benefit": 0,
          "is_best": 0,
          "is_new": 0,
          "is_virtual": 0,
          "virtual_type": 0,
          "add_time": 1636965538,
          "is_postage": 0,
          "is_del": 0,
          "mer_use": 0,
          "give_integral": "20.00",
          "cost": "5555.00",
          "is_seckill": 0,
          "is_bargain": null,
          "is_good": 0,
          "is_sub": 0,
          "is_vip": 0,
          "ficti": 0,
          "browse": 0,
          "code_path": "",
          "soure_link": "",
          "temp_id": 0,
          "spec_type": 1,
          "activity": "0,1,2,3",
          "spu": "5010049484313",
          "label_id": "",
          "video_link": "",
          "command_word": "",
          "recommend_list": "",
          "vip_product": 0,
          "presale": 0,
          "presale_start_time": 0,
          "presale_end_time": 0,
          "presale_day": 0,
          "logistics": "1,2",
          "freight": 2,
          "custom_form": "[]",
          "is_limit": 0,
          "limit_type": 0,
          "limit_num": 0,
          "min_qty": 1,
          "collect": 100,
          "likes": 0,
          "visitor": 4117,
          "cate_name": "官方商城",
          "stock_attr": true,
          "product_type": "普通商品"
        },
        {
          "id": 92,
          "mer_id": 0,
          "image": "https://demo26.crmeb.net/uploads/attach/2021/11/13/8742a1741c7e3647d5d5cd8320fcdc94.jpg",
          "recommend_image": "",
          "slider_image": [
            "https://demo26.crmeb.net/uploads/attach/2021/11/13/8742a1741c7e3647d5d5cd8320fcdc94.jpg",
            "https://demo26.crmeb.net/uploads/attach/2021/11/13/753e8c9001a4d56ce53170e0fd67095a.jpg",
            "https://demo26.crmeb.net/uploads/attach/2021/11/13/4b198eb5b7e4f2502c1e496aefe7e7b2.jpg"
          ],
          "store_name": "绣球永生花网红干花花束大束超大拍照室内客厅鲜花ins干花真花带花瓶干花套装组合装饰家居摆设加花瓶套装",
          "store_info": "绣球永生花网红干花花束大束超大拍照室内客厅鲜花ins干花真花带花瓶干花套装组合装饰家居摆设加花瓶套装 单支浅兰永生绣球花，杆长55厘米",
          "keyword": "",
          "bar_code": "",
          "cate_id": "592",
          "price": "149.00",
          "vip_price": "0.00",
          "ot_price": "130.00",
          "postage": "0.00",
          "unit_name": "件",
          "sort": 555,
          "sales": 17,
          "stock": "12071",
          "is_show": 1,
          "is_hot": 1,
          "is_benefit": 0,
          "is_best": 0,
          "is_new": 0,
          "is_virtual": 0,
          "virtual_type": 0,
          "add_time": 1636788779,
          "is_postage": 0,
          "is_del": 0,
          "mer_use": 0,
          "give_integral": "10000.00",
          "cost": "130.00",
          "is_seckill": 0,
          "is_bargain": null,
          "is_good": 0,
          "is_sub": 0,
          "is_vip": 0,
          "ficti": 5,
          "browse": 0,
          "code_path": "",
          "soure_link": "",
          "temp_id": 0,
          "spec_type": 1,
          "activity": "0,1,2,3",
          "spu": "9848579829690",
          "label_id": "",
          "video_link": "",
          "command_word": "",
          "recommend_list": "",
          "vip_product": 0,
          "presale": 0,
          "presale_start_time": 0,
          "presale_end_time": 0,
          "presale_day": 0,
          "logistics": "1,2",
          "freight": 2,
          "custom_form": "[]",
          "is_limit": 1,
          "limit_type": 2,
          "limit_num": 1,
          "min_qty": 1,
          "collect": 34,
          "likes": 0,
          "visitor": 823,
          "cate_name": "",
          "stock_attr": true,
          "product_type": "普通商品"
        },
        {
          "id": 94,
          "mer_id": 0,
          "image": "https://demo26.crmeb.net/uploads/attach/2023/09/20230921/948bbba7e2a48643d79eb569c7613b84.png",
          "recommend_image": "",
          "slider_image": [
            "https://demo26.crmeb.net/uploads/attach/2023/09/20230921/948bbba7e2a48643d79eb569c7613b84.png",
            "//img.alicdn.com/imgextra/i2/2805777512/O1CN01zAYv5y25MXwxlT3jP_!!2805777512.jpg",
            "//img.alicdn.com/imgextra/i3/2805777512/O1CN01BLyddr25MXwymQf0e_!!2805777512.jpg",
            "//img.alicdn.com/imgextra/i1/2805777512/O1CN01Ge6ed425MXwvRmS0k_!!2805777512.jpg",
            "//img.alicdn.com/imgextra/i3/2805777512/O1CN01yZ8mVk25MXwwKppvF_!!2805777512.jpg",
            "//img.alicdn.com/imgextra/i2/2805777512/O1CN01TMt4Wf25MXwvRofFI_!!2805777512.jpg"
          ],
          "store_name": "圣诞节生日礼物女生送给闺蜜女朋友老师实用的高级感伴手礼品小众",
          "store_info": "",
          "keyword": "",
          "bar_code": "",
          "cate_id": "592",
          "price": "89.00",
          "vip_price": "0.00",
          "ot_price": "70.00",
          "postage": "0.00",
          "unit_name": "件",
          "sort": 554,
          "sales": 19,
          "stock": "502",
          "is_show": 1,
          "is_hot": 1,
          "is_benefit": 0,
          "is_best": 0,
          "is_new": 0,
          "is_virtual": 0,
          "virtual_type": 0,
          "add_time": 1636789921,
          "is_postage": 0,
          "is_del": 0,
          "mer_use": 0,
          "give_integral": "100.00",
          "cost": "54.00",
          "is_seckill": 0,
          "is_bargain": null,
          "is_good": 0,
          "is_sub": 0,
          "is_vip": 0,
          "ficti": 0,
          "browse": 0,
          "code_path": "",
          "soure_link": "",
          "temp_id": 0,
          "spec_type": 1,
          "activity": "0,2,3,1",
          "spu": "4910152511115",
          "label_id": "",
          "video_link": "",
          "command_word": "",
          "recommend_list": "",
          "vip_product": 0,
          "presale": 0,
          "presale_start_time": 0,
          "presale_end_time": 0,
          "presale_day": 0,
          "logistics": "1,2",
          "freight": 2,
          "custom_form": "[]",
          "is_limit": 0,
          "limit_type": 0,
          "limit_num": 0,
          "min_qty": 1,
          "collect": 18,
          "likes": 0,
          "visitor": 490,
          "cate_name": "",
          "stock_attr": true,
          "product_type": "普通商品"
        },
        {
          "id": 89,
          "mer_id": 0,
          "image": "https://demo26.crmeb.net/uploads/attach/2021/11/20211113/2fbe723632427b0768df24c4f23c6cad.png",
          "recommend_image": "",
          "slider_image": [
            "https://demo26.crmeb.net/uploads/attach/2021/11/20211113/2fbe723632427b0768df24c4f23c6cad.png",
            "https://demo26.crmeb.net/uploads/attach/2021/11/20211113/64d188477292aa4ae2df8da7e63aca79.png"
          ],
          "store_name": "家居梵高系列联名款饭盒袋大容量手拎保温实用方便 星月夜饭盒袋",
          "store_info": "",
          "keyword": "家居梵高系列联名款饭盒袋大容量手拎保温实用方便 星月夜饭盒袋",
          "bar_code": "",
          "cate_id": "592",
          "price": "350.00",
          "vip_price": "10.00",
          "ot_price": "300.00",
          "postage": "0.00",
          "unit_name": "件",
          "sort": 553,
          "sales": 24,
          "stock": "169",
          "is_show": 1,
          "is_hot": 1,
          "is_benefit": 0,
          "is_best": 0,
          "is_new": 0,
          "is_virtual": 0,
          "virtual_type": 0,
          "add_time": 1636777038,
          "is_postage": 0,
          "is_del": 0,
          "mer_use": 0,
          "give_integral": "100.00",
          "cost": "300.00",
          "is_seckill": 0,
          "is_bargain": null,
          "is_good": 0,
          "is_sub": 0,
          "is_vip": 1,
          "ficti": 0,
          "browse": 0,
          "code_path": "",
          "soure_link": "",
          "temp_id": 0,
          "spec_type": 1,
          "activity": "1,0,2,3",
          "spu": "1015710267816",
          "label_id": "0",
          "video_link": "",
          "command_word": "",
          "recommend_list": "",
          "vip_product": 1,
          "presale": 0,
          "presale_start_time": 0,
          "presale_end_time": 0,
          "presale_day": 0,
          "logistics": "1,2",
          "freight": 2,
          "custom_form": "[]",
          "is_limit": 0,
          "limit_type": 0,
          "limit_num": 0,
          "min_qty": 1,
          "collect": 37,
          "likes": 0,
          "visitor": 975,
          "cate_name": "",
          "stock_attr": true,
          "product_type": "普通商品"
        },
        {
          "id": 90,
          "mer_id": 0,
          "image": "https://demo26.crmeb.net/uploads/attach/2021/11/20211113/d027e61bbc73dde6b449c98591d33cab.png",
          "recommend_image": "",
          "slider_image": [
            "https://demo26.crmeb.net/uploads/attach/2021/11/20211113/d027e61bbc73dde6b449c98591d33cab.png",
            "https://demo26.crmeb.net/uploads/attach/2021/11/20211113/893ee4e3e9aac6a17f4a51333c401587.png",
            "https://demo26.crmeb.net/uploads/attach/2021/11/20211113/e1f0775557c6dad75f8cd8daa92de212.png",
            "https://demo26.crmeb.net/uploads/attach/2021/11/20211113/1fc81cf905019f2d24bea3cd7d39aecf.png"
          ],
          "store_name": "干花花束植物干花真花永生花勿忘我干花多色草莓果干花办公室客厅茶几餐桌摆放装饰艺术插花小花束 红色草莓果12支（不含花瓶）",
          "store_info": "",
          "keyword": "干花花束植物干花真花永生花勿忘我干花多色草莓果干花办公室客厅茶几餐桌摆放装饰艺术插花小花束 红色草莓果12支（不含花瓶）",
          "bar_code": "",
          "cate_id": "592",
          "price": "68.00",
          "vip_price": "0.00",
          "ot_price": "60.00",
          "postage": "0.00",
          "unit_name": "束",
          "sort": 450,
          "sales": 29,
          "stock": "568",
          "is_show": 1,
          "is_hot": 0,
          "is_benefit": 0,
          "is_best": 0,
          "is_new": 0,
          "is_virtual": 0,
          "virtual_type": 0,
          "add_time": 1636777942,
          "is_postage": 0,
          "is_del": 0,
          "mer_use": 0,
          "give_integral": "100.00",
          "cost": "50.00",
          "is_seckill": 0,
          "is_bargain": null,
          "is_good": 0,
          "is_sub": 0,
          "is_vip": 0,
          "ficti": 156,
          "browse": 0,
          "code_path": "",
          "soure_link": "",
          "temp_id": 0,
          "spec_type": 1,
          "activity": "0,1,2,3",
          "spu": "5456515516116",
          "label_id": "0",
          "video_link": "",
          "command_word": "",
          "recommend_list": "",
          "vip_product": 0,
          "presale": 0,
          "presale_start_time": 0,
          "presale_end_time": 0,
          "presale_day": 0,
          "logistics": "1,2",
          "freight": 2,
          "custom_form": "[]",
          "is_limit": 0,
          "limit_type": 0,
          "limit_num": 0,
          "min_qty": 1,
          "collect": 15,
          "likes": 0,
          "visitor": 383,
          "cate_name": "",
          "stock_attr": true,
          "product_type": "普通商品"
        },
        {
          "id": 91,
          "mer_id": 0,
          "image": "//img.alicdn.com/imgextra/i2/81420182/O1CN01i7KBzG1DDOnZWDoqU_!!81420182.jpg",
          "recommend_image": "",
          "slider_image": [
            "//img.alicdn.com/imgextra/i2/81420182/O1CN01i7KBzG1DDOnZWDoqU_!!81420182.jpg",
            "//img.alicdn.com/imgextra/i1/81420182/O1CN01h9iJGt1DDOnq9muMH_!!81420182.jpg",
            "//img.alicdn.com/imgextra/i1/81420182/O1CN01Pvpkoo1DDOnivAlPf_!!81420182.jpg",
            "//img.alicdn.com/imgextra/i2/81420182/O1CN01TD080P1DDOniSPPhr_!!81420182.jpg"
          ],
          "store_name": "CHICVEN「摩登工业」科技感反光渐变羽绒服立领面包服外套女冬季",
          "store_info": "",
          "keyword": "CHICVEN「摩登工业」科技感反光渐变羽绒服立领面包服外套女冬季",
          "bar_code": "",
          "cate_id": "592",
          "price": "481.00",
          "vip_price": "0.00",
          "ot_price": "649.00",
          "postage": "0.00",
          "unit_name": "扎",
          "sort": 447,
          "sales": 1,
          "stock": "449",
          "is_show": 1,
          "is_hot": 1,
          "is_benefit": 0,
          "is_best": 0,
          "is_new": 0,
          "is_virtual": 0,
          "virtual_type": 0,
          "add_time": 1636786789,
          "is_postage": 0,
          "is_del": 0,
          "mer_use": 0,
          "give_integral": "100.00",
          "cost": "500.00",
          "is_seckill": 0,
          "is_bargain": null,
          "is_good": 0,
          "is_sub": 0,
          "is_vip": 0,
          "ficti": 30,
          "browse": 0,
          "code_path": "",
          "soure_link": "",
          "temp_id": 0,
          "spec_type": 1,
          "activity": "0,1,2,3",
          "spu": "5348525362147",
          "label_id": "0",
          "video_link": "",
          "command_word": "",
          "recommend_list": "",
          "vip_product": 0,
          "presale": 0,
          "presale_start_time": 0,
          "presale_end_time": 0,
          "presale_day": 0,
          "logistics": "1,2",
          "freight": 2,
          "custom_form": "[]",
          "is_limit": 0,
          "limit_type": 0,
          "limit_num": 0,
          "min_qty": 1,
          "collect": 6,
          "likes": 0,
          "visitor": 166,
          "cate_name": "",
          "stock_attr": true,
          "product_type": "普通商品"
        },
        {
          "id": 72,
          "mer_id": 0,
          "image": "https://demo26.crmeb.net/uploads/attach/2021/11/20211113/2fbe723632427b0768df24c4f23c6cad.png",
          "recommend_image": "",
          "slider_image": [
            "https://demo26.crmeb.net/uploads/attach/2021/11/20211113/2fbe723632427b0768df24c4f23c6cad.png"
          ],
          "store_name": "COACH/蔻驰女士swinger腋下包单肩包麻将包豌豆包",
          "store_info": "COACH/蔻驰女士swinger腋下包单肩包麻将包豌豆包",
          "keyword": "女式包",
          "bar_code": "",
          "cate_id": "592",
          "price": "3000.00",
          "vip_price": "0.00",
          "ot_price": "2900.00",
          "postage": "0.00",
          "unit_name": "件",
          "sort": 446,
          "sales": 117,
          "stock": "237",
          "is_show": 1,
          "is_hot": 1,
          "is_benefit": 0,
          "is_best": 0,
          "is_new": 0,
          "is_virtual": 0,
          "virtual_type": 0,
          "add_time": 1632362003,
          "is_postage": 0,
          "is_del": 0,
          "mer_use": 0,
          "give_integral": "100.00",
          "cost": "2899.00",
          "is_seckill": 0,
          "is_bargain": null,
          "is_good": 0,
          "is_sub": 0,
          "is_vip": 0,
          "ficti": 123,
          "browse": 0,
          "code_path": "",
          "soure_link": "",
          "temp_id": 0,
          "spec_type": 1,
          "activity": "0,1,2,3",
          "spu": "5148102169645",
          "label_id": "",
          "video_link": "",
          "command_word": "",
          "recommend_list": "",
          "vip_product": 0,
          "presale": 0,
          "presale_start_time": 0,
          "presale_end_time": 0,
          "presale_day": 0,
          "logistics": "1,2",
          "freight": 2,
          "custom_form": "[]",
          "is_limit": 0,
          "limit_type": 0,
          "limit_num": 0,
          "min_qty": 1,
          "collect": 20,
          "likes": 0,
          "visitor": 740,
          "cate_name": "",
          "stock_attr": true,
          "product_type": "普通商品"
        }
      ]
    };
  },
  methods: {
    findPageDataList() { },
    remove() { },
    edit() { },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
    pageSizeChage(size) {
      this.size = size
      this.findPageDataList()
    },
  }
}

</script>
<style lang='scss' scoped>
</style>